import React, { useEffect, useState } from "react";
import ResidentsTable from "./components/ResidentsTable";
import { residentsData } from "../default/variables/columnsData";
import residentsTableData from "../default/variables/residentsTableData.json";
import ResidentsModal from "./components/ResidentsModal";
import ResidentCardModal from "./components/ResidentCardModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllResidents } from "redux/actions/residentActions";
import Loader from "components/loader";

function Residents() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const {residents,loading,error} = useSelector((state) => state.allResidents);

  useEffect(() => {
    dispatch(getAllResidents());
  }, []);
    

  return (
    <div>
      <div className="mt-3">
        <div className="m-3 flex justify-between">
          <span className=""></span>
          <span className="w-40">
            <button
              onClick={() => setShowModal(true)}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add New
            </button>
          </span>
        </div>
        {loading ? (
          <Loader />
        ):(
          <ResidentsTable
          columnsData={residentsData}
          tableData={residents}
          pageSize={20}
        />
        )}
        <ResidentsModal setShowModal={setShowModal} showModal={showModal} />

        
      </div>
    </div>
  );
}

export default Residents;
