import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import TransactionsTable from "./components/TransactionsTable";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader";
import { useEffect } from "react";
import { getAllResidents } from "redux/actions/residentActions";
import { getAllVisitors } from "redux/actions/visitorActions";
import { getAllPayments } from "redux/actions/paymentActions";

const Dashboard = () => {

  const { userInfo } = useSelector((state) => state.userSignin);
  const dispatch = useDispatch();
  const { residents, loading, error } = useSelector(
    (state) => state.allResidents
  );
  const { payments, loading: loadingPayments } = useSelector(
    (state) => state.allPayments
  );

  const { visitors, loading: loadingVisitors } = useSelector(
    (state) => state.allVisitors
  );

  useEffect(() => {
    dispatch(getAllVisitors());
  }, []);

  useEffect(() => {
    dispatch(getAllResidents());
  }, []);

  useEffect(() => {
    dispatch(getAllPayments());
  }, []);

  const totalAmount = payments?.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.amount;
  }, 0);


  return (
    <div>
      {/* Card widget */}
      {loading || (loadingVisitors && <Loader />)}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        {userInfo?.isAdmin && (
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Earnings"}
          subtitle={totalAmount}
        />
        )}
        
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Total Residents"}
          subtitle={residents?.length}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Visitors"}
          subtitle={visitors?.length}
        />
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
        {/* Complex Table , Task & Calendar */}
        {loadingPayments ? (
          <Loader />
        ) : (
          payments && (
            <TransactionsTable
              columnsData={columnsDataComplex}
              tableData={payments}
              pageSize={20}
            />
          )
        )}

        {/* Task chart & Calendar */}
      </div>
    </div>
  );
};

export default Dashboard;
