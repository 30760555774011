//add visitor reducers
export const addVisitorReducer = (state = {}, action) => {
    switch (action.type) {
        case "ADD_VISITOR_REQUEST":
        return { loading: true };
        case "ADD_VISITOR_SUCCESS":
        return { loading: false, success: true, visitor: action.payload };
        case "ADD_VISITOR_FAIL":
        return { loading: false, error: action.payload };
        case "ADD_VISITOR_RESET":
        return {};
        default:
        return state;
    }
    };

    //get all visitors reducers
    export const getAllVisitorsReducer = (state = { visitors: [] }, action) => {
        switch (action.type) {
            case "GET_ALL_VISITORS_REQUEST":
            return { loading: true };
            case "GET_ALL_VISITORS_SUCCESS":
            return { loading: false, visitors: action.payload };
            case "GET_ALL_VISITORS_FAIL":
            return { loading: false, error: action.payload };
            default:
            return state;
        }
        }

        //visitor clock out reducers
        export const visitorClockOutReducer = (state = {}, action) => {
            switch (action.type) {
                case "VISITOR_CLOCKOUT_REQUEST":
                return { loading: true };
                case "VISITOR_CLOCKOUT_SUCCESS":
                return { loading: false, success: true, visitor: action.payload };
                case "VISITOR_CLOCKOUT_FAIL":
                return { loading: false, error: action.payload };
                case "VISITOR_CLOCKOUT_RESET":
                return {};
                default:
                return state;
            }
            }