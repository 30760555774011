import React, { useEffect, useState } from "react";
import { residentsData, visitorsData } from "../default/variables/columnsData";
import VisitorsModal from "./components/VisitorsModal";
import VisitorsTable from "./components/VisitorsTable";
import visitorsTableData from "../default/variables/visitorsTableData.json";
import { useDispatch, useSelector } from "react-redux";
import { getAllVisitors } from "redux/actions/visitorActions";
import Loader from "components/loader";

function Visitors() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const { visitors, loading, error } = useSelector(
    (state) => state.allVisitors
  );

  useEffect(() => {
    dispatch(getAllVisitors());
  }, []);

  return (
    <div>
      <div className="mt-3 flex flex-col">
        <div className="m-3 flex justify-between">
          <span className=""></span>
          <span className="w-40">
            <button
              onClick={() => setShowModal(true)}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add New
            </button>
          </span>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <VisitorsTable
            columnsData={visitorsData}
            tableData={visitors}
            pageSize={10}
          />
        )}
        <VisitorsModal setShowModal={setShowModal} showModal={showModal} />
      </div>
    </div>
  );
}

export default Visitors;
