import { useEffect, useState } from "react";
import data from "../../default/variables/residentsTableData.json";
import { useDispatch, useSelector } from "react-redux";
import { getAllResidents } from "redux/actions/residentActions";

function FinanceModal({ showModal, setShowModal }) {
  const [selectedName, setSelectedName] = useState("");


  
  const dispatch = useDispatch();
  const {residents,loading,error} = useSelector((state) => state.allResidents);

  useEffect(() => {
    dispatch(getAllResidents());
  }, []);


  const handleSelectChange = (event) => {
    setSelectedName(event.target.value);
  };

  // Filter names based on user selection
  const filteredNames = residents?.filter((person) => person.name === selectedName);

  const selectedPerson = residents?.find((person) => person.name === selectedName);
  const defaultHouseNumber = selectedPerson ? selectedPerson.houseNumber : "";


  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 backdrop-blur-lg">
          <div className="relative rounded-lg bg-white shadow dark:bg-white/5">
            <div className="relative w-[500px] rounded-lg bg-white shadow dark:bg-white/0">
              <button
                type="button"
                className="bg-transparent absolute top-3 right-2.5 ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-navy-700 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
                onClick={() => setShowModal(false)}
              >
                <svg
                  className="h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="px-6 py-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Add New Transaction
                </h3>
                <form className="space-y-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select a Name
                    </label>
                    <select
                      value={selectedName}
                      onChange={handleSelectChange}
                      className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:text-white dark:placeholder-gray-300"
                    >
                      <option value="">Select a Name</option>
                      {residents?.map((person, index) => (
                        <option key={index} value={person.name}>
                          {person.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label
                      htmlFor="houseno"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      House No
                    </label>
                    <input
                      type="number"
                      name="houseno"
                      id="houseno"
                      className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                      placeholder="House No"
                      readOnly
                      required
                      value={defaultHouseNumber}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="amount"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Amount Paid
                    </label>
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                      placeholder="Amount"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="paymentmethod"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Payment Method
                    </label>
                    <select
                      name="paymentmethod"
                      id="paymentmethod"
                      className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                    >
                      <option value="mpesa" className="dark:text-gray-800">
                        Mpesa
                      </option>
                      <option value="bank" className="dark:text-gray-800">
                        Bank
                      </option>
                    </select>
                  </div>

                  
                  <div></div>

                  <button
                    type="submit"
                    className="w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Add
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FinanceModal;
