import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { signout } from "redux/actions/userActions";
import { toast } from "react-toastify";

const AuthProvider = ({ children }) => {
  const { userInfo } = useSelector((state) => state.userSignin);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use useNavigate hook to navigate

  useEffect(() => {
    // Check if the token is expired
    const isTokenExpired = () => {
      if (userInfo && userInfo.token) {
        const decodedToken = jwtDecode(userInfo.token);
        return decodedToken.exp * 1000 < Date.now(); // Check if the expiration time is before the current time
      }
      return false;
    };

    if (userInfo && isTokenExpired()) {
      dispatch(signout()); // Dispatch the action to sign out the user
      toast.error("Your session has expired. Please sign in again.");
      navigate("/auth/sign-in"); // Use navigate function to redirect
    }

    if (!userInfo) {
      navigate("/auth/sign-in"); // Use navigate function to redirect
    }
  }, [userInfo, dispatch, navigate]); // Add dependencies for the useEffect

  return children;
};

export default AuthProvider;
