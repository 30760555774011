import Loader from "components/loader";
import LoadingButton from "components/loadingButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllResidents } from "redux/actions/residentActions";
import { deleteResident } from "redux/actions/residentActions";

function DelModal({ showDelModal, setShowDelModal, data }) {
  const closeModal = () => {
    setShowDelModal(false);
  };

  const { resident, loading, error, sucess } = useSelector(state => state.residentDelete)

  const dispatch = useDispatch();

  const handleDeleteResident = (e) => {
    e.preventDefault();
    dispatch(deleteResident(data._id));
    };

    useEffect(() => {
        if (resident) {
            toast.success(resident.message);
            dispatch(getAllResidents());
            closeModal();
            // Clear the resident data to prevent the effect from running again

            //clear state
            return () => {
              dispatch({ type: "DELETE_RESIDENT_RESET" });
            }
        }
        if (error) {
            toast.error(error);
        }
    
        // Clear state when the component unmounts
        return () => {
            dispatch({ type: "DELETE_RESIDENT_RESET" });
        };
    }, [resident, error]);
    


  return (
    <>
      {showDelModal && (
        <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 backdrop-blur-lg">
          <div className="relative rounded-lg bg-white shadow dark:bg-white/5">

            {loading && <Loader />}
            <div className="relative sm:w-[500px] rounded-lg bg-white shadow dark:bg-white/0">


              <button
                type="button"
                className="bg-transparent absolute top-3 right-2.5 ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-navy-700 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
                onClick={() => setShowDelModal(false)}
              >
                <svg
                  className="h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="px-6 py-6 lg:px-8">
                <p className="mb-4 text-md font-medium text-gray-900 dark:text-white">
                  Are you sure you want to delete this resident?
                </p>
                <form className="space-y-6">
                  <div className="flex justify-between">
                    <button
                      onClick={() => setShowDelModal(false)}
                      type="submit"
                      className="mt-4 w-32 rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Cancel
                    </button>
                    <button
                    onClick={handleDeleteResident}
                      className="mt-4 w-32 rounded-lg bg-red-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                     {loading ? <LoadingButton /> : "Delete"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DelModal;
