import React, { useContext, useEffect } from "react";
import { InvoiceContext } from "../../context/InvoiceContext";
import InvoiceItem from "./InvoiceItem";
import { useDispatch, useSelector } from "react-redux";
import { getAllInvoices } from "redux/actions/invoiceActions";

const InvoiceItemList = () => {
  const { invoices} = useSelector((state) => state.allInvoices);


 
  return (
    <ul>
      {invoices?.map((invoice) => (
        <InvoiceItem invoice={invoice} key={invoice._id} />
      ))}
    </ul>
  );
};
export default InvoiceItemList;
