import moment from "moment";
import * as Yup from "yup";



const terms = [1, 7, 14, 30];

const validationSchema = Yup.object().shape({
  from: Yup.object().shape({
    address: Yup.string().required("*required"),
    city: Yup.string().required("*required"),
    postCode: Yup.string().required("*required"),
  }),
  to: Yup.object().shape({
    name: Yup.string().required("*required"),
    houseNumber: Yup.string().required("*required"),
    number: Yup.string().required("*required"),
  }),
  desc: Yup.string().required("*required"),
  itemList: Yup.array()
    .min(1, "An item must be added")
    .of(
      Yup.object().shape({
        itemName: Yup.string().required("*required"),
        qty: Yup.number("").required("*required"),
        price: Yup.number("").required("*required"),
      })
    ),
});

export {  terms, validationSchema };
