import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import axios from "../../../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCamera } from "react-icons/ai";
import { MdOutlineCameraswitch } from "react-icons/md";
import { addVisitor } from "redux/actions/visitorActions";
import { toast } from "react-toastify";
import LoadingButton from "components/loadingButton";
import { getAllVisitors } from "redux/actions/visitorActions";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

function VisitorsModal({ showModal, setShowModal }) {
  const [step, setStep] = useState("data"); // Initial step is "data"
  const [img, setImg] = useState(null);
  const [image, setImage] = useState(null);
  const webcamRef = useRef(null);

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");

  const { loading, error, visitor } = useSelector((state) => state.visitorAdd);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  }, [webcamRef]);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || number === "" || idNumber === "" || houseNumber === "") {
      return alert("Please fill all the fields");
    } else {
      setStep("photo");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const submitData = () => {
    dispatch(addVisitor(name, houseNumber, number, idNumber, image));
  };

  useEffect(() => {
    if (visitor) {
      toast.success(visitor.message);
      dispatch(getAllVisitors());
      setName("");
      setNumber("");
      setIdNumber("");
      setHouseNumber("");
      setImage(null);
    }

    closeModal();
    if (error) {
      toast.error(error);
    }

    // Clear state when the component unmounts
    return () => {
      dispatch({ type: "ADD_VISITOR_RESET" });
    };
  }, [visitor, error]);

  return (
    <>
      {showModal && (
        <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 backdrop-blur-lg">
          <div className="h-[900px relative rounded-lg bg-white shadow dark:bg-white/5">
            <div className="relative w-[600px] rounded-lg bg-white shadow dark:bg-white/0">
              <button
                type="button"
                className="bg-transparent absolute top-3 right-2.5 ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-navy-700 hover:bg-gray-200 hover:text-gray-900 dark:text-gray-800 dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
                onClick={() => {
                  setShowModal(false);
                  setStep("data");
                  setImage(null);
                }}
              >
                <svg
                  className="h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
              <div className="px-6 py-6 lg:px-8">
                {step === "data" ? (
                  // Step 1: Enter data
                  <div>
                    <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                      Enter Visitor Information
                    </h3>
                    <div className="space-y-6">
                      <div>
                        <label
                          htmlFor="amount"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                          placeholder="Full Name"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="accountno"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Phone Number
                        </label>
                        <input
                          type="number"
                          name="number"
                          id="number"
                          className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                          placeholder="Phone Number"
                          required
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="idNumber"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                        >
                          ID Number
                        </label>
                        <input
                          type="number"
                          name="idNumber"
                          id="idNumber"
                          className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                          placeholder="ID Number"
                          required
                          value={idNumber}
                          onChange={(e) => setIdNumber(e.target.value)}
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="houseno"
                          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                        >
                          House No
                        </label>
                        <input
                          type="number"
                          name="houseNumber"
                          id="houseNumber"
                          className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                          placeholder="ID Number"
                          required
                          value={houseNumber}
                          onChange={(e) => setHouseNumber(e.target.value)}
                        />
                      </div>
                      <div></div>

                      <button
                        onClick={handleSubmit}
                        className="w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : (
                  // Step 2: Take a photo
                  <div>
                    <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                      Take a Photo
                    </h3>
                    {image === null ? (
                      <>
                        <Webcam
                          audio={false}
                          mirrored={true}
                          height={300}
                          width="100%"
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                        />
                        <div className="mt-3 flex items-center justify-center">
                          <button
                            onClick={capture}
                            className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            <AiOutlineCamera className="text-xl" />
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <img src={image} alt="screenshot" />
                        <div className="flex items-center justify-center">
                          <button
                            onClick={() => setImage(null)}
                            className="mt-4 inline-flex w-20 justify-center rounded-full bg-orange-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            <MdOutlineCameraswitch className="text-xl" />
                          </button>
                        </div>

                        {loading ? (
                          <LoadingButton />
                        ) : (
                          <button
                            onClick={submitData}
                            className="mt-4 w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            Submit Data
                          </button>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VisitorsModal;
