import axios from "../../utils/axios";


//get all payments
export const getAllPayments = () => async (dispatch) => {
        dispatch({type:"GET_ALL_PAYMENTS_REQUEST"})
        try {
                const {data} = await axios.get("/payments");
                dispatch({type:"GET_ALL_PAYMENTS_SUCCESS",payload:data})
        } catch (error) {
                const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
                dispatch({type:"GET_ALL_PAYMENTS_FAIL",payload:message})
        }
        }
