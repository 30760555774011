import React, { useContext, useEffect, useState } from "react";
import { InvoiceContext } from "../../context/InvoiceContext";
import { motion } from "framer-motion";
import { FieldArray, Formik } from "formik";
import {  validationSchema } from "./data";
import Input from "./Input";
import Terms from "./Terms";
import ItemList from "./ItemList";
import DatePicker from "./DatePicker";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { genId } from "../../functions";
import arrowLeft from "../../images/icon-arrow-left.svg";
import { createInvoice } from "redux/actions/invoiceActions";
import Loader from "components/loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllResidents } from "redux/actions/residentActions";
import moment from "moment";
import { getAllInvoices } from "redux/actions/invoiceActions";

const CreateInvoiceForm = ({ setShowForm }) => {
    const [selectedName, setSelectedName] = useState("");

 const {loading,error,invoice} = useSelector((state) => state.invoiceCreate);
 const {residents,loading:loadingResidents,error:errorResidents} = useSelector((state) => state.allResidents);

 const dispatch = useDispatch();

 const selectedPerson = residents?.find((person) => person.name === selectedName);

  const saveInvoice = (data) => {
  
    let total = 0;
    data.itemList.forEach((item) => {
      total += item.qty * item.price;
    });
    dispatch(createInvoice({ data,selectedPerson,total, id: genId() }))


  };


  useEffect(()=>{
    if(invoice){
      toast.success("Invoice created successfully");
      setShowForm(false)
      dispatch(getAllInvoices());
    }
    if(error){
      toast.error(error);
    }
    

    //clear the state
    return () => {
      dispatch({type:"CREATE_INVOICE_RESET"})
    }

  },[invoice,error])

  
  useEffect(() => {
    dispatch(getAllResidents());
  }, []);



  
  const initialFormValues = {
    from: { address: "", city: "", postCode: "", country: "" },
    to: {
      name: "", 
      number: "", 
      houseNumber:"", 
    },
    invoiceDate: moment().format("DD MM yyyy"),
    terms: 1,
    desc: "",
    itemList: [],
  };



  const handleSelectChange = async(e) => {
    setSelectedName(e.target.value);
   
  };

  const draftInvoice = (data) => {
    createInvoice({ ...data, status: "Draft", id: genId() });
    setShowForm(false);
  };














  return (
    <div>
      {loading || loadingResidents && <Loader />}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 0.6,
        }}
        className="overlay"
        onClick={() => setShowForm(false)}></motion.div>
      <motion.div
        className="formBody formHeight1"
        initial={{ x: "-100vh" }}
        animate={{ x: 0 }}>
        <div className="mobile3:max-w-container2 m-auto h-full">
          <button
            to="/"
            className="text-text1 dark:text-text1-dark font-bold text-xs flex items-center gap-7 mb-6 mobile2:hidden pl-3"
            onClick={() => setShowForm(false)}>
            <img src={arrowLeft} alt="" className="mb-1" /> Go back
          </button>
          <h2 className="text-text1 dark:text-text1-dark font-bold text-lg2 mb-5 mobile2:mb-12 pl-3">
            New Invoice
          </h2>

          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => saveInvoice(values)}
            validationSchema={validationSchema}>
            {(props) => (
              <SimpleBar style={{ maxHeight: "100%" }}>
                <form className="h-full overflow-y-auto px-3 mb-5" action="">
                  <div className="mb-12">
                    <p className="text-purple font-bold text-xs mb-6">
                      Bill From
                    </p>
                    <Input
                      label={"Street Adress"}
                      handleChange={props.handleChange("from.address")}
                      name={"address"}
                      error={props.touched.from && props.errors.from}
                      value={props.values.from.address}
                    />
                    <div className="mobile3:grid grid-cols-3 gap-6">
                      <div className="col-span-2 grid grid-cols-2 gap-6">
                        <Input
                          label={"City"}
                          handleChange={props.handleChange("from.city")}
                          name={"city"}
                          error={props.touched.from && props.errors.from}
                          value={props.values.from.city}
                        />
                        <Input
                          label={"Post Code"}
                          handleChange={props.handleChange("from.postCode")}
                          name={"postCode"}
                          error={props.touched.from && props.errors.from}
                          value={props.values.from.postCode}
                        />
                      </div>
                      <Input
                        label={"Country"}
                        name={"country"}
                        error={props.touched.from && props.errors.from}
                        value={"Kenya"}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text-purple font-bold text-xs mb-6">
                      Bill To
                    </p>
                    <div className="hidden">

                    <Input
                      label={"Client Name"}
                      handleChange={props.handleChange("to.name")}
                      name={"name"}
                      error={props.touched.to && props.errors.to}
                      value={selectedPerson?.name}
                      className="!hidden name"
                    />
                     </div>

                     <select
                      value={selectedName}
                      onChange={(e) => handleSelectChange(e)}
                      className="mb-6 block w-full rounded-md border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:text-white dark:placeholder-gray-300"
                    >
                      <option value="">Select a Name</option>
                      {residents?.map((person, index) => (
                        <option key={index} value={person.name}>
                          {person.name}
                        </option>
                      ))}
                    </select>
                    <Input
                      label={"Client Phone Number"}
                      handleChange={props.handleChange("to.number")}
                      name={"number"}
                      placeholder="e.g. 254 712 345 678"
                      error={props.touched.to && props.errors.to}
                      value={selectedPerson?.number}
                        
                    />
                    <Input
                      label={"House Number"}
                      handleChange={props.handleChange("to.houseNumber")}
                      name={"houseNumber"}
                      error={props.touched.to && props.errors.to}
                      value={selectedPerson?.houseNumber}
                    />
                    
                  </div>
                  <div className="grid grid-cols-2 gap-6 mt-4">
                    <DatePicker props={props} invoiceDate={new Date()} />
                    <Terms props={props} />
                  </div>
                  
                  <p className="text-gray5 text-lg1 font-bold">Item List</p>
                  <FieldArray
                    name="itemList"
                    render={(arrayHelpers) => (
                      <ItemList props={props} arrayHelpers={arrayHelpers} />
                    )}
                  />
                  {props.touched.itemList &&
                    typeof props.errors.itemList === "string" && (
                      <p className="text-xxs font-semibold text-red mt-8">
                        - {props.errors.itemList}
                      </p>
                    )}
                </form>
                <div className="text-xs flex justify-between items-center sticky bottom-0 pb-3.5 left-0 w-full bg-bg2 dark:bg-bg1-dark h-28 shadow-shadow1 px-3">
                  <button
                    className="font-bold text-purple bg-purple-300 rounded-3xl py-4 mobile2:px-6 px-4 mobile1:text-xs text-xxs"
                    type="button"
                    onClick={() => setShowForm(false)}>
                    Discard
                  </button>
                  <div>
                    <button
                      className="font-bold text-text2 dark:text-gray3 bg-bg3 rounded-3xl py-4 mobile2:px-6 px-4 mr-2 mobile1:text-xs text-xxs"
                      type="button"
                      onClick={() => draftInvoice(props.values)}>
                      Save as Draft
                    </button>
                    <button
                      className="font-bold text-white dark:text-text1-dark bg-purple-600 rounded-3xl py-4 mobile2:px-6 px-4 mobile1:text-xs text-xxs"
                      type="button"
                      onClick={() => saveInvoice(props.values)}
                      >
                     {loading ? "loading...." : "Save"}
                    </button>
                  </div>
                </div>
              </SimpleBar>
            )}
          </Formik>
        </div>
      </motion.div>
    </div>
  );
};

export default CreateInvoiceForm;
