import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { Fragment, useMemo, useState } from "react";
import Progress from "components/progress";
import ImageModal from "./ImageModal";
import ClockoutActions from "./ClockoutActions";
import moment from "moment";

const VisitorsTable = (props) => {
  const { columnsData, tableData, pageSize } = props;
  const [showImageModal, setShowImageModal] = useState(false);
  const [url, setUlr] = useState("");

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  initialState.pageSize = pageSize;

  return (
    <Card extra="w-full h-full px-6 pb-6 sm:overflow-x-auto">
      <div className="relative flex flex-col sm:flex-row items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Vistors
        </div>
        <CardMenu />
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full overflow-x-scroll">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-4 sm:pr-28 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-900">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "IMAGE") {
                      data = (
                        <img
                          onClick={() => {
                            setShowImageModal(true);
                            setUlr(cell.value);
                          }}
                          src={cell.value}
                          alt="Image"
                          style={{ maxWidth: "70px", maxHeight: "70px" }}
                        />
                      );
                    }
                    if (cell.column.Header === "NAME") {
                      data = (
                        <p className="text-sm text-text2 dark:text-text2-dark">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "NUMBER") {
                      data = (
                        <p className="text-sm text-text2 dark:text-text2-dark">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "HOUSENUMBER") {
                      data = (
                        <p className="text-sm text-text2 dark:text-text2-dark">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "IDNUMBER") {
                      data = (
                        <p className="text-sm text-text2 dark:text-text2-dark">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "CLOCKEDIN") {
                      const formattedDate = moment(cell.value).format('YYYY-MM-DD hh:mm-A');
                      data = (
                        <p className="text-sm text-orange-800 dark:text-text2-dark">
                          {formattedDate.split(' ').map((part, index) => (
                            <Fragment key={index}>
                              {part}
                              <br />
                            </Fragment>
                          ))}
                        </p>
                      );
                  
                    
                    } else if (cell.column.Header === "CLOCKEDOUT") {
                      data = (
                        <ClockoutActions data={row.original} />
                      );
                    }
                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ImageModal
        showImageModal={showImageModal}
        setShowImageModal={setShowImageModal}
        ulr={url}
      />
    </Card>
  );
};

export default VisitorsTable;
