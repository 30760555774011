//add new resident

export const addResidentReducer = (state = {}, action) => {
    switch (action.type) {
        case "ADD_RESIDENT_REQUEST":
        return {
            loading: true,
        };
        case "ADD_RESIDENT_SUCCESS":
        return {
            loading: false,
            success: true,
            resident: action.payload,
        };
        case "ADD_RESIDENT_FAILED":
        return {
            loading: false,
            error: action.payload,
        };
        case "ADD_RESIDENT_RESET":
        return {};
        default:
        return state;
    }
    };


//get all residents  reducers
export const getAllResidentsReducer = (state = {residents:[]}, action) => {
    switch (action.type) {
        case "GET_ALL_RESIDENTS_REQUEST":
        return {
            loading: true,
        };
        case "GET_ALL_RESIDENTS_SUCCESS":
        return {
            loading: false,
            residents: action.payload,
        };
        case "GET_ALL_RESIDENTS_FAILED":
        return {
            loading: false,
            error: action.payload,
        };
        default:
        return state;
    }
    };


    //delete resident reducers
export const deleteResidentReducer = (state = {}, action) => {
    switch (action.type) {
        case "DELETE_RESIDENT_REQUEST":
        return {
            loading: true,
        };
        case "DELETE_RESIDENT_SUCCESS":
        return {
            loading: false,
            success: true,
            resident: action.payload,
        };
        case "DELETE_RESIDENT_FAILED":
        return {
            loading: false,
            error: action.payload,
        };
        case "DELETE_RESIDENT_RESET":
        return {};
        default:
        return state;
    }
    };

    //get resident by id reducers
export const getResidentByIdReducer = (state = {resident:{}}, action) => {
    switch (action.type) {
        case "GET_RESIDENT_BY_ID_REQUEST":
        return {
            loading: true,
        };
        case "GET_RESIDENT_BY_ID_SUCCESS":
        return {
            loading: false,
            resident: action.payload,
        };
        case "GET_RESIDENT_BY_ID_FAILED":
        return {
            loading: false,
            error: action.payload,
        };
        default:
        return state;
    }
    };