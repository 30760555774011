import Cookies from "js-cookie";
import axios from "../../utils/axios";

//register a user action
export const register =
  (phoneNumber, username, email, password, role, accessCode) => async (dispatch) => {
    dispatch({
      type: "USER_REGISTER_REQUEST",
      payload: { phoneNumber, username, email, password, role, accessCode },
    });
    try {
      const { data } = await axios.post("/users/register", {
        phoneNumber, username, email, password, role, accessCode
      });
      dispatch({ type: "USER_REGISTER_SUCCESS", payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: "USER_REGISTER_FAIL", payload: message });
    }
  };

//signin a user action
export const signin = (email, password) => async (dispatch) => {
  dispatch({ type: "USER_SIGNIN_REQUEST", payload: { email, password } });
  try {
    const { data } = await axios.post("/users/login", { email, password });
    dispatch({ type: "USER_SIGNIN_SUCCESS", payload: data });
    Cookies.set("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: "USER_SIGNIN_FAIL", payload: message });
  }
};

//get user details action
export const getUserDetails = (userId) => async (dispatch, getState) => {
  dispatch({ type: "USER_DETAILS_REQUEST", payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();

  try {
    const { data } = await axios.get(`/user/${userInfo.user.id}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
      },
    });
    dispatch({ type: "USER_DETAILS_SUCCESS", payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: "USER_DETAILS_FAIL", payload: message });
  }
};

//signout a user action
export const signout = () => (dispatch) => {
  Cookies.remove("userInfo");
  dispatch({ type: "USER_SIGNOUT" });
  document.location.href = "/auth/sign-in";
};
