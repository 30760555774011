import axios from "../../utils/axios.js"

export const addResident = (name,houseNumber,number,idNumber) => async (dispatch) => {
   dispatch({type:"ADD_RESIDENT_REQUEST"})
    try {
        const {data} = await axios.post("/residents/add", {name,houseNumber,number,idNumber});
        dispatch({type:"ADD_RESIDENT_SUCCESS",payload:data})
    } catch (error) {
        const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
        dispatch({type:"ADD_RESIDENT_FAILED",payload:message})
    }
}


//get all residents

export const getAllResidents = () => async (dispatch) => {
    dispatch({type:"GET_ALL_RESIDENTS_REQUEST"})
    try {
        const {data} = await axios.get("/residents");
        dispatch({type:"GET_ALL_RESIDENTS_SUCCESS",payload:data})
    } catch (error) {
        const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
        dispatch({type:"GET_ALL_RESIDENTS_FAILED",payload:message})
    }
}

//delete resident
export const deleteResident = (id) => async (dispatch) => {
    dispatch({type:"DELETE_RESIDENT_REQUEST"})
    try {
        const {data} = await axios.delete(`/residents/delete/${id}`);
        dispatch({type:"DELETE_RESIDENT_SUCCESS",payload:data})
    }
    catch (error) {
        const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
        dispatch({type:"DELETE_RESIDENT_FAILED",payload:message})
    }
}


//get resident by id
export const getResidentById = (id) => async (dispatch) => {
    dispatch({type:"GET_RESIDENT_BY_ID_REQUEST"})
    try {
        const {data} = await axios.get(`/residents/${id}`);
        dispatch({type:"GET_RESIDENT_BY_ID_SUCCESS",payload:data})
    }
    catch (error) {
        const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
        dispatch({type:"GET_RESIDENT_BY_ID_FAILED",payload:message})
    }
}