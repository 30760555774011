import Loader from "components/loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getResidentById } from "redux/actions/residentActions";

function Typewriter({ text }) {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIndex < text.length) {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }, 50);

    return () => clearTimeout(timer);
  }, [text, currentIndex]);

  return <span>{displayText}</span>;
}

function ViewResident() {
  const { resident, loading, error } = useSelector(
    (state) => state.singleResident
  );
  const params = useParams();
  const dispatch = useDispatch();

  // State for password input and visibility
  const [password, setPassword] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  const handleButtonClick = (value) => {
    if (value === 'C') {
      // Clear the input
      setPassword('');
    } else {
      // Append the clicked button value to the input if it's a number
      if (/^\d$/.test(value)) {
        setPassword((prevValue) => prevValue + value);
      }
    }
  };

  useEffect(() => {
    dispatch(getResidentById(params.id));
  }, [dispatch, params.id]);

  // Function to handle password submission
  const handlePasswordSubmit = () => {
    if (password === "2244") { 
      setIsPasswordCorrect(true);
    }
    else {
      alert("Wrong password. Please try again.");
    }

  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white rounded-lg p-6 shadow-md w-96">
        <h2 className="mb-4 text-2xl font-semibold text-indigo-600">
          Resident Details
        </h2>
        {!isPasswordCorrect ? (
          <div>
            <p>Please enter the password to view resident details:</p>
            <div>
      <input type="text" value={password} readOnly className="hidden"/>
      <div className="bg-gray-200 p-4 rounded-md">
      <input
        type="password"
        value={password}
        readOnly
        className="w-full p-2 rounded-md mb-2 text-right"
      />
      <div className="grid grid-cols-3 gap-2">
        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'C'].map(
          (value) => (
            <button
              key={value}
              onClick={() => handleButtonClick(value)}
              className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
            >
              {value}
            </button>
          )
        )}
      </div>
    </div>
    </div>
           
            <button onClick={handlePasswordSubmit} className="w-full mt-3 p-2 bg-green-500 text-white rounded-md">Submit</button>
          </div>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <ul>
              <li className="mb-4">
                <span className="font-semibold">Name:</span>{" "}
                <Typewriter text={resident.name} />
              </li>
              <li className="mb-4">
                <span className="font-semibold">House Number:</span>{" "}
                <Typewriter text={JSON.stringify(resident.houseNumber)} />
              </li>
              <li className="mb-4">
                <span className="font-semibold">Card Number:</span>{" "}
                <Typewriter text={resident.cardNo} />
              </li>
              <li className="mb-4">
                <span className="font-semibold">Phone Number:</span>{" "}
                <Typewriter text={resident.number} />
              </li>
              <li className="mb-4">
                <span className="font-semibold">Amount Due:</span>{" "}
                Ksh{" "}
                <Typewriter text={JSON.stringify(resident.amountDue)} />
              </li>
            </ul>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ViewResident;
