import Loader from "components/loader";
import LoadingButton from "components/loadingButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllResidents } from "redux/actions/residentActions";
import { addResident } from "redux/actions/residentActions";

function ResidentsModal({ showModal, setShowModal }) {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");

  const dispatch = useDispatch();

  const { resident,loading, error, sucess } = useSelector((state) => state.residentAdd);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleAddResident = (e) => {
    e.preventDefault();
    dispatch(addResident(name, houseNumber, number, idNumber));
  };

  console.log(resident);

  useEffect(() => {
    if (resident) {
      toast.success(resident.message);
      dispatch(getAllResidents());
      setName("");
      setNumber("");
      setIdNumber("");
      setHouseNumber("");
      
      closeModal();
    }
    if (error) {
      toast.error(error);
    }

    // Clear state when the component unmounts
    return () => {
      dispatch({ type: "ADD_RESIDENT_RESET" });
    };
  }, [resident, error]);

  


  return (
    <>
      {showModal && (
        <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 backdrop-blur-lg">
          <div className="relative rounded-lg bg-white shadow dark:bg-white/5">
            <div className="relative w-[500px] rounded-lg bg-white shadow dark:bg-white/0">
              <button
                type="button"
                className="bg-transparent absolute top-3 right-2.5 ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-navy-700 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
                onClick={() => setShowModal(false)}
              >
                <svg
                  className="h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="px-6 py-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Add Resident
                </h3>
                <form className="space-y-6" onSubmit={handleAddResident}>
                  <div>
                    <label
                      htmlFor="amount"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                      placeholder="Full Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="accountno"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Phone Number
                    </label>
                    <input
                      type="number"
                      name="number"
                      id="number"
                      className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                      placeholder="Phone Number"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="idno"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      ID Number
                    </label>
                    <input
                      type="number"
                      name="idno"
                      id="idno"
                      className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                      placeholder="ID Number"
                      value={idNumber}
                      onChange={(e) => setIdNumber(e.target.value)}
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="houseno"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      House No
                    </label>
                    <input
                      type="number"
                      name="houseno"
                      id="idno"
                      className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                      placeholder="House Number"
                      value={houseNumber}
                      onChange={(e) => setHouseNumber(e.target.value)}
                      required
                    />
                  </div>
                  <div></div>

                  {loading ? (
                    <LoadingButton />
                  ) : (
                    <button
                      type="submit"
                      className="w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Add
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ResidentsModal;
