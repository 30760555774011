import React, { useRef } from "react";
import QRCode from "qrcode.react"; // You may need to install this package
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import { AiOutlineCloudDownload } from "react-icons/ai";

function ResidentCard({ _id,cardNo, cardHolderName, houseNumber }) {
  const cardRef = useRef(null);

  const downloadCard = () => {
    if (!cardRef.current) return;
  
    // Create a promise to capture the HTML content as an image
    html2canvas(cardRef.current).then((canvas) => {
      // Create a new jsPDF instance
      const pdf = new jsPDF('p', 'mm', 'a4');
  
      // Calculate the aspect ratio to fit the content on the page
      const imgWidth = 100; // A4 page width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      // Add the captured canvas image as a page to the PDF
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight);
  
      // Save the PDF with a specified name
      pdf.save('resident_card.pdf');
    });
  };


  return (
    <>
      <button
        className="my-2 flex cursor-pointer items-center"
        onClick={() => downloadCard()}
      >
        <AiOutlineCloudDownload className="text-2xl text-orange-300" />
        <p>Download</p>
      </button>
      <div
        ref={cardRef}
        className="relative m-auto h-56 w-96 transform  bg-gradient-to-br from-purple-600 from-blue-900 via-blue-500 via-indigo-800 to-teal-500 to-purple-700 text-white shadow-2xl transition-transform hover:scale-110 dark:bg-gradient-to-br"
      >
        <div className="absolute top-8 w-full px-8">
          <div className="flex justify-between">
            <div className="space-y-3">
              <div>
                <p className="font-light">Name</p>
                <p className="font-medium tracking-widest">{cardHolderName}</p>
              </div>

              <div className="mt-2 flex items-center space-x-2">
                <p className="font-light">House No:</p>
                <p className="font-medium tracking-widest">{houseNumber}</p>
              </div>

              <div className="pt-1">
                <p className="font-light">Card Number</p>
                <p className="tracking-more-wider font-medium">{cardNo}</p>
              </div>
            </div>
            <div className="h-full rounded-xl border-2 border-purple-500 p-2">
              <QRCode
                value={`https:security.sokoright.com/residents/resident/${_id}`}
                size={130}
                bgColor="#333" // Customize QR code background color
                fgColor="#fff" // Customize QR code foreground color
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResidentCard;
