import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError } from "react-icons/md";
import { useMemo } from "react";
import Progress from "components/progress";
import ActionsMenu from "./ActionsMenu";

const ResidentsTable = (props) => {
  const { columnsData, tableData,pageSize } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = pageSize;


  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div class="relative flex items-center justify-between pt-4">
        <div class="text-xl font-bold text-navy-700 dark:text-white">
         Residents
        </div>
        <CardMenu />
      </div>

      <div class="mt-8 overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-900 dark:text-gray-200">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "NAME") {
                      data = (
                        <p className="text-sm text-text2 dark:text-text2-dark">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "NUMBER") {
                      data = (
                
                        <p className="text-sm text-text2 dark:text-text2-dark">
                            {cell.value}
                          </p>
                      );
                    } else if (cell.column.Header === "HOUSENUMBER") {
                      data = (
                        <p className="text-sm text-text2 dark:text-text2-dark">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "DATE") {
                      data = (
                        <p className="text-sm text-text2 dark:text-text2-dark">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "STATUS") {
                      data =(
                        <p className="text-sm text-text2 dark:text-text2-dark">
                          {cell.value}
                        </p>
                      )
                    } else if (cell.column.Header === "ACTIONS") {
                      data = (
                        <ActionsMenu
                          data={row.original}
                        />
                      
                      )
                    }
                      else if (cell.column.Header === "CARDNO") {
                        data =(
                          <p className="text-sm text-text2 dark:text-text2-dark">
                          {cell.value}
                          </p>
                        )
                    
                      
                    }
                    
                    
                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ResidentsTable;
