//get all payments

export const getAllPaymentsReducers = (state = { payments: [] }, action) => {
    switch (action.type) {
        case "GET_ALL_PAYMENTS_REQUEST":
        return {
            loading: true,
        };
        case "GET_ALL_PAYMENTS_SUCCESS":
        return {
            loading: false,
            payments: action.payload,
        };
        case "GET_ALL_PAYMENTS_FAIL":
        return {
            loading: false,
            error: action.payload,
        };
        default:
        return state;
    }
    };