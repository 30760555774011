import React, { useState } from "react";
import { AiFillIdcard, AiOutlineUser } from "react-icons/ai";
import { MdDeleteSweep } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import ResidentCardModal from "./ResidentCardModal";
import DelModal from "./DelModal";

function ActionsMenu(props) {
  const [showCardModal, setShowCardModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);

  return (
    <div>
      <p className="hover:text-black flex cursor-pointer items-center gap-2 space-x-2 text-xl text-gray-600 hover:font-medium">
        <span className="text-red-500">
          <MdDeleteSweep onClick={() => setShowDelModal(true)}/>
        </span>
        <span className="text-blue-400">
          <BiEdit />
        </span>
        <span className="text-orange-400">
          <AiFillIdcard onClick={() => setShowCardModal(true)} />
        </span>
      </p>

      <ResidentCardModal
        setShowCardModal={setShowCardModal}
        showCardModal={showCardModal}
        data={props.data}
      />

      <DelModal 
      showDelModal={showDelModal}
      setShowDelModal={setShowDelModal}
      data={props.data}
      />
    </div>
  );
}

export default ActionsMenu;
