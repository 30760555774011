import React, { useContext, useEffect, useState } from "react";
import Header from "../components/home/Header";
import FilteredInvoices from "../components/invoice/FilteredInvoices";
import InvoiceItemList from "../components/invoice/InvoiceItemList";
import Empty from "../components/layouts/Empty";
import { motion } from "framer-motion";
import { Fragment } from "react";
import { InvoiceContext } from "../context/InvoiceContext";
import CreateInvoiceForm from "../components/forms/CreateInvoiceForm";
import { useDispatch, useSelector } from "react-redux";
import { getAllInvoices } from "redux/actions/invoiceActions";
import Loader from "components/loader";

const Invoices = () => {
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const invoiceContext = useContext(InvoiceContext);
  const { filter } = invoiceContext;

  const { invoices ,loading} = useSelector((state) => state.allInvoices);

  const variants = {
    exit: {
      y: -30,
      opacity: 0,
    },
  };

  const dispatch = useDispatch();


  useEffect(()=>{
    dispatch(getAllInvoices())
  },[])

  console.log(invoices)

  return (
    <motion.div className="px-3" variants={variants} exit="exit">
      {loading && <Loader />}
      <Header setShow={setShow} show={show} setShowForm={setShowForm}/>
      <Fragment>
        {!filter ? <InvoiceItemList /> : <FilteredInvoices />}
        {showForm && (
          <CreateInvoiceForm showForm={showForm} setShowForm={setShowForm} />
        )}
      </Fragment>

      {invoices?.length === 0 && <Empty />}
    </motion.div>
  );
};
export default Invoices;
