import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { addResidentReducer, deleteResidentReducer, getAllResidentsReducer, getResidentByIdReducer } from "./reducers/residentReducers";
import { addVisitorReducer, getAllVisitorsReducer, visitorClockOutReducer } from "./reducers/visitorReducers";
import { createInvoiceReducer, getAllInvoicesReducer, getInvoiceByIdReducer, payInvoiceReducer } from "./reducers/invoiceReducers";
import Cookies from "js-cookie";
import { registerReducer, signinReducer } from "./reducers/userReducers";
import { getAllPaymentsReducers } from "./reducers/paymentReducers";

const data = Cookies.get("userInfo");
const userInfo = data ? JSON.parse(data) : null;

const initialState = {
  userSignin: {
    userInfo,
  },
};

const reducer = combineReducers({
  residentAdd: addResidentReducer,
  allResidents: getAllResidentsReducer,
  residentDelete: deleteResidentReducer,
  visitorAdd: addVisitorReducer,
  allVisitors: getAllVisitorsReducer,
  clockoutVisitor: visitorClockOutReducer,
  invoiceCreate: createInvoiceReducer,
  allInvoices: getAllInvoicesReducer,
  singleInvoice: getInvoiceByIdReducer,
  userSignin: signinReducer,
  userRegister: registerReducer,
  invoicePay: payInvoiceReducer,
  statusCheck: createInvoiceReducer,
  allPayments: getAllPaymentsReducers,
  singleResident: getResidentByIdReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
