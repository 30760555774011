export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
 
  {
    Header:"NUMBER",
    accessor:"PhoneNumber"
  },
  {
    Header: "HOUSENUMBER",
    accessor: "houseNumber",
  },
  {
    Header: "MPESA CODE",
    accessor: "MpesaReceiptNumber",
  },
  {
    Header: "STATUS",
    accessor: "paymentStatus",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "METHOD",
    accessor: "paymentMethod",
  },
  {
    Header: "AMOUNT",
    accessor: "amount",
  },
];

export const residentsData = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "NUMBER",
    accessor: "number",
  },
  {
    Header: "HOUSENUMBER",
    accessor: "houseNumber",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "CARDNO",
    accessor: "cardNo",
  },
  {
    Header: "ACTIONS",
    accessor: "actions",
  },
];

export const visitorsData = [
  {
    Header: "IMAGE",
    accessor: "image",
  },
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "NUMBER",
    accessor: "number",
  },
  {
    Header: "HOUSENUMBER",
    accessor: "houseNumber",
  },
  {
    Header: "IDNUMBER",
    accessor: "idNumber",
  },
  
  {
    Header: "CLOCKEDIN",
    accessor: "clockedIn",
  },
  {
    Header: "CLOCKEDOUT",
    accessor: "clockedOut",
  },
];
