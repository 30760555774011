import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdOutlineAttachMoney,
  MdOutlinePeopleOutline,
  MdHomeWork,
  MdFingerprint,
  MdOutlineMoney,
} from "react-icons/md";
import Finance from "views/admin/finance";
import Residents from "views/admin/residents";
import Visitors from "views/admin/visitors";
import Invoices from "views/admin/invoices/index/Home";
import Register from "views/auth/Register";



const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Finance",
    layout: "/admin",
    path: "finance",
    icon: <MdOutlineAttachMoney className="h-6 w-6" />,
    component: <Finance />,
    adminOnly: true,
    
  },
  {
    name: "Invoices",
    layout: "/admin",
    path: "invoices",
    icon: <MdOutlineMoney className="h-6 w-6" />,
    component: <Invoices />,
    adminOnly: true,
  },
  {
    name: "Residents",
    layout: "/admin",
    path: "residents",
    icon: <MdOutlinePeopleOutline className="h-6 w-6" />,
    component: <Residents />,
  },
  {
    name: "Workers/House Md",
    layout: "/admin",
    path: "workers",
    icon: <MdHomeWork className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Visitors",
    layout: "/admin",
    path: "visitors",
    icon: <MdOutlinePeopleOutline className="h-6 w-6" />,
    component: <Visitors />,
  },
  {
    name: "Logs",
    layout: "/admin",
    path: "logs",
    icon: <MdFingerprint className="h-6 w-6" />,
    component: <MainDashboard />,
  },

 
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
 
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Register",
    layout: "/auth",
    path: "register",
    icon: <MdLock className="h-6 w-6" />,
    component: <Register />,
    adminOnly: true,
  }
];
export default routes;
