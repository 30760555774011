import axios from "../../utils/axios";

//create a new data
export const createInvoice =
  (invoiceData, selectedPerson, total, id) => async (dispatch) => {
    dispatch({ type: "CREATE_INVOICE_REQUEST" });
    try {
      const { data } = await axios.post("/invoices/add", {
        invoiceData,
        selectedPerson,
        total,
        id,
      });
      dispatch({ type: "CREATE_INVOICE_SUCCESS", payload: { data } });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: "CREATE_INVOICE_FAIL", payload: message });
    }
  };

//get all invoices
export const getAllInvoices = () => async (dispatch) => {
  dispatch({ type: "GET_ALL_INVOICES_REQUEST" });
  try {
    const { data } = await axios.get("/invoices");
    dispatch({ type: "GET_ALL_INVOICES_SUCCESS", payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: "GET_ALL_INVOICES_FAIL", payload: message });
  }
};

//get invoice by id
export const getInvoiceById = (id) => async (dispatch) => {
  dispatch({ type: "GET_INVOICE_BY_ID_REQUEST" });
  try {
    const { data } = await axios.get(`/invoices/${id}`);
    dispatch({ type: "GET_INVOICE_BY_ID_SUCCESS", payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: "GET_INVOICE_BY_ID_FAIL", payload: message });
  }
};

export const payInvoice =
  (invoice, phoneNumber, amount) => async (dispatch, getState) => {
    try {
      dispatch({
        type: "INVOICE_PAY_REQUEST",
        payload: invoice,
        phoneNumber,
        amount,
      });

      const { data } = await axios.post("/pay/invoice", {
        invoice,
        phoneNumber,
        amount,
      });
      dispatch({ type: "INVOICE_PAY_SUCCESS", payload: data });
    } catch (error) {
      const message = error.response.data.message
        ? error.response.data.message
        : error.message;
      dispatch({ type: "INVOICE_PAY_FAIL", payload: message });
    }
  };

export const checkStatus = (checkId) => async (dispatch) => {
  const { data } = await axios.post(`/pay/confirmPayment/${checkId}`);
  try {
    dispatch({ type: "CHECK_PAY_REQUEST", payload: checkId });
    dispatch({ type: "CHECK_PAY_SUCCESS", payload: data });
  } catch (error) {
    dispatch({ type: "CHECK_PAY_FAIL", payload: error });
  }
};
