import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import Header from "../components/viewInvoice/Header";
import arrowLeft from "../images/icon-arrow-left.svg";
import { Link, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import BottomButtons from "../components/viewInvoice/BottomButtons";
import DeleteModal from "../components/viewInvoice/DeleteModal";
import InvoiceDetails from "../components/viewInvoice/InvoiceDetails";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceById } from "redux/actions/invoiceActions";
import Loader from "components/loader";

const ViewClientInvoice = ({ match, history }) => {
  const { invoice, loadingInvoice } = useSelector(
    (state) => state.singleInvoice
  );

  const [showForm, setShowForm] = useState(false);
  const [modal, setModal] = useState(null);
  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvoiceById(id));
  }, [id]); // Add id to the dependency array

  console.log(invoice);

  return (
    <>
      {loadingInvoice ? (
        <Loader />
      ) : (
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="mb-12 px-3"
        >
          {invoice._id && (
            <Fragment>
              <Link
                to="/"
                className="mt-32 mb-8 flex items-center gap-7 text-xs font-bold text-text1 dark:text-text1-dark tab:mt-16"
              >
                {/* Your back button */}
              </Link>
              <Header
                invoice={invoice}
                setShowForm={setShowForm}
                history={history}
                setModal={setModal}
              />
              {/* Check if invoice is defined before rendering InvoiceDetails */}
              {invoice._id && <InvoiceDetails invoice={invoice} />}

              {modal && <DeleteModal setModal={setModal} modal={modal} />}
            </Fragment>
          )}
        </motion.div>
      )}
    </>
  );
};

export default ViewClientInvoice;
