import React, { useEffect } from "react";
import TransactionsTable from "../default/components/TransactionsTable";
import { columnsDataComplex } from "../default/variables/columnsData";
import FinanceModal from "./components/FinanceModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllPayments } from "redux/actions/paymentActions";
import Loader from "components/loader";

function Finance() {
  const [showModal, setShowModal] = React.useState(false);
  const dispatch = useDispatch();

  const { payments, loading } = useSelector((state) => state.allPayments);

  useEffect(() => {
    dispatch(getAllPayments());
  }, []);


  return (
    <div>
      {loading && <Loader />}
      <div className="mt-3">
        <div className="m-3 flex justify-between">
          <span className=""></span>
          <span className="w-40">
            <button
              onClick={() => setShowModal(true)}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add New
            </button>
          </span>
        </div>
        {loading ? (
          <Loader />
        ):(
          
          <TransactionsTable
            columnsData={columnsDataComplex}
            tableData={payments}
            pageSize={20}
          />
        )}

        <FinanceModal showModal={showModal} setShowModal={setShowModal} />
      </div>
    </div>
  );
}

export default Finance;
