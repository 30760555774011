import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "../../../../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getInvoiceById } from "redux/actions/invoiceActions";
import { payInvoice } from "redux/actions/invoiceActions";
import { toast } from "react-toastify";

const Buttons = ({ invoice, setShowForm, setModal }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState(invoice.total || "");

  const [load, setLoad] = useState(false);
  const [results, setResults] = useState({});
  const params = useParams();
  const invoicePay = useSelector((state) => state.invoicePay);
  const { loadingPay, successPay, errorPay, details } = invoicePay;
  const memoizedResults = useMemo(() => results, [results]);

  const statusCheck = useSelector((state) => state.statusCheck);

  const markAsPaid = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handlePayment = () => {

    setModalOpen(true);
    setResults({});
    dispatch(payInvoice(invoice, phoneNumber, amount));
  };

  useEffect(() => {
    if (details && details.ResponseCode === "0" && !load) {
      setLoad(true);
      setTimeout(function () {
        confirmPayment();
      }, 3000);
    }
  }, [details, load]);

  useEffect(() => {
    if (memoizedResults) {
      setLoad(false);

      if (memoizedResults.errorMessage) {
        confirmPayment();
      }

      if (memoizedResults.ResultCode && memoizedResults.ResultCode === "0") {
        setTimeout(() => {
          dispatch(getInvoiceById(params.id));
          setModalOpen(false);
          dispatch({ type: "INVOICE_PAY_RESET" });
        }, 3000);
      }
    }

    // clear state
    return () => {
      // ...
    };
  }, [memoizedResults, params.id]);

  const confirmPayment = () => {
    axios
      .post(`pay/confirmPayment/${details.CheckoutRequestID}`)
      .then((res) => {
        const { data } = res;
        setResults(data);
        console.log(data);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div className="gap-2 mobile2:flex">
      {invoice.status !== "Paid" && (
        <button
          className="btn dark:hover:bg-purple-hover bg-green-500 text-white hover:opacity-75 dark:hover:opacity-100"
          onClick={markAsPaid}
        >
          Pay Now
        </button>
      )}

      {modalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="min-w-screen flex min-h-screen items-center justify-center px-4">
            <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
            <div className="transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:w-1/3 w-96">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="sm:items-start">
                  <button
                    className="absolute top-4 right-4 inline-flex h-8 w-8 items-center justify-center rounded-md bg-red-100 transition-colors duration-300 hover:bg-red-200 focus:bg-red-200 focus:outline-none"
                    onClick={handleCloseModal}
                  >
                    <svg
                      className="h-6 w-6 text-red-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </button>
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    {!loadingPay && (
                      <>
                        <div>
                          <label
                            htmlFor="phoneNumber"
                            className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Mpesa Phone Number
                          </label>
                          <input
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                            placeholder="eg 254700000000"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                          />
                        </div>
                        <div className="my-5">
                          <label
                            htmlFor="Amount"
                            className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                          >
                            Amount
                          </label>
                          <input
                            type="text"
                            name="Amount"
                            id="Amount"
                            className="block w-full rounded-lg border border-gray-300 bg-white/0 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500  dark:text-white dark:placeholder-gray-300"
                            placeholder="eg 1000"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            required
                          />
                        </div>
                        <button
                          className="hover:bg-brad-blue mt-4 w-full rounded-full bg-green-500 py-2 px-4 font-bold text-white"
                          onClick={handlePayment}
                        >
                          Pay Now
                        </button>
                      </>
                    )}

                    <div className="mt-2 text-center">
                      {loadingPay ? (
                        <h5 className="font-medium">
                          <i className="fas fa-spinner fa-spin fa-2x m-4"></i>
                          <br /> Processing Payment
                        </h5>
                      ) : load ? (
                        <h5 className="font-medium">
                          <i className="fas fa-spinner fa-spin fa-2x m-4"></i>
                          <br /> Please ChecK Your Phone For M-Pesa PIN
                        </h5>
                      ) : null}

                      {errorPay && (
                        <div className="text-red-500">{errorPay}</div>
                      )}

                      {results && (
                        <div className="mt-6 flex flex-wrap items-center justify-center space-x-2">
                          {results.ResultCode === "0" ? (
                            <h5 className="font-medium capitalize text-green-500">
                              <i className="fas fa-check-circle fa-2x m-4"></i>
                              <br /> {results.ResultDesc}
                            </h5>
                          ) : results.errorMessage ? (
                            <h5 className="font-medium capitalize text-green-500">
                              <i className="fas fa-spinner fa-spin fa-2x m-4"></i>
                              <br /> {results.errorMessage}
                            </h5>
                          ) : details?.errorMessage ? (
                            <span>{details?.errorMessage}</span>
                          ) : results && results.ResultDesc && !loadingPay ? (
                            <>
                              <h5 className="m-4 w-full font-medium capitalize text-red-500">
                                <i className="fas fa-times-circle fa-2x m-4"></i>
                                <br /> {results.ResultDesc || "Please Wait..."}
                              </h5>
                              <button
                                className="hover:bg-brad-blue mt-4 rounded-full bg-green-500 py-2 px-4 font-bold text-white"
                                onClick={handlePayment}
                              >
                                Try Again
                              </button>
                            </>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Buttons;
