import React from 'react'

function ImageModal({showImageModal,setShowImageModal,ulr}) {
  return (
    <>
      {showImageModal && (
        <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 backdrop-blur-lg">
          <div className="relative rounded-lg bg-white shadow dark:bg-white/5">
            <div className="relative w-[500px] rounded-lg bg-white shadow dark:bg-white/0">
              <button
                type="button"
                className="bg-transparent absolute top-3 right-2.5 ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg text-sm text-navy-700 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="authentication-modal"
                onClick={() => setShowImageModal(false)}
              >
                <svg
                  className="h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="px-6 py-6 lg:px-8">
               <img src={ulr} alt="image" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ImageModal