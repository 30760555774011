//create a new invoice reducers
export const createInvoiceReducer = (state = {}, action) => {
    switch (action.type) {
        case "CREATE_INVOICE_REQUEST":
        return {
            loading: true,
        };
        case "CREATE_INVOICE_SUCCESS":
        return {
            loading: false,
            success: true,
            invoice: action.payload,
        };
        case "CREATE_INVOICE_FAIL":
        return {
            loading: false,
            error: action.payload,
        };
        case "CREATE_INVOICE_RESET":
        return {};
        default:
        return state;
    }
    }


    //get all invoices reducers
    export const getAllInvoicesReducer = (state = { invoices: [] }, action) => {
    switch (action.type) {
        case "GET_ALL_INVOICES_REQUEST":
        return {
            loading: true,
        };
        case "GET_ALL_INVOICES_SUCCESS":
        return {
            loading: false,
            invoices: action.payload,
        };
        case "GET_ALL_INVOICES_FAIL":
        return {
            loading: false,
            error: action.payload,
        };
        case "GET_ALL_INVOICES_RESET":
        return {
            invoices: [],
        };
        default:
        return state;
    }
    };

    //get invoice by id reducers
    export const getInvoiceByIdReducer = (state = { invoice: {} }, action) => {
    switch (action.type) {
        case "GET_INVOICE_BY_ID_REQUEST":
        return {
            loadingInvoice: true,
        };
        case "GET_INVOICE_BY_ID_SUCCESS":
        return {
            loadingInvoice: false,
            invoice: action.payload,
        };
        case "GET_INVOICE_BY_ID_FAIL":
        return {
            loadingInvoice: false,
            error: action.payload,
        };
        default:
        return state;
    }
    }


    export const payInvoiceReducer = (state = {}, action) => {
        switch (action.type) {
        case "INVOICE_PAY_REQUEST":
          return { loadingPay: true }
        case "INVOICE_PAY_SUCCESS":
          return { loadingPay: false, successPay: true, details: action.payload }
        case "INVOICE_PAY_FAIL":
          return { loadingPay: false, errorPay: action.payload }
        case "INVOICE_PAY_RESET":
          return {}
        default:
          return state
        }
      }
      
      export const statusInvoiceReducer = (state = { loadingCheck: false }, action) => {
        switch (action.type) {
        case "CHECK_PAY_REQUEST":
          return { loadingCheck: true }
        case "CHECK_PAY_SUCCESS":
          return {
            loadingCheck: false,
            results: action.payload,
            successCheck: true
          }
        case "CHECK_PAY_FAIL":
          return { loadingCheck: false, errorCheck: action.payload }
        default:
          return state
        }
      }