import Loader from "components/loader";
import moment from "moment";
import React, { useEffect } from "react";
import { FcAlarmClock } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllVisitors } from "redux/actions/visitorActions";
import { visitorClockOut } from "redux/actions/visitorActions";

function ClockoutActions(props) {
  console.log(props);
  const dispatch = useDispatch();
  const { loading, error, visitor } = useSelector(
    (state) => state.clockoutVisitor
  );

  const handleClockOut = () => {
    dispatch(visitorClockOut(props.data._id));
  };

  useEffect(() => {
    if (visitor) {
      toast.success(visitor.message);
      dispatch(getAllVisitors());
    }
    if (error) {
      toast.error(error);
    }

    //clear state
    return () => {
      dispatch({ type: "VISITOR_CLOCKOUT_RESET" });
    };
  }, [visitor, error]);

  const formattedDate = moment(props.data.clockedOut).format('YYYY-MM-DD HH:mm-A');


  return (
    <div>
      {loading && <Loader />}
      {props.data.clockedOut === null ? (
        <p className="ml-8 flex cursor-pointer items-end gap-2  space-x-2 text-xl text-gray-600 hover:font-medium hover:text-black">
          <span className="text-red-500">
            <FcAlarmClock onClick={handleClockOut} />
          </span>
        </p>
      ) : (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {formattedDate.split(" ").map((part, index) => (
            <p className="text-sm text-green-500 dark:text-text2-dark"
            key={index}>
             {part}

              <br />
            </p>
          ))}
        </p>
      )}
    </div>
  );
}

export default ClockoutActions;
