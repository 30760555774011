import axios from "../../utils/axios";

//add new resident
export const addVisitor = (name, houseNumber, number, idNumber, image) => async (dispatch) => {
    dispatch({type:"ADD_VISITOR_REQUEST"})
     try {
          const {data} = await axios.post("/visitors/add", {name, houseNumber, number, idNumber, image});
          dispatch({type:"ADD_VISITOR_SUCCESS",payload:data})
     } catch (error) {
          const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
          dispatch({type:"ADD_VISITOR_FAIL",payload:message})
     }
    }

    //get all visitors
export const getAllVisitors = () => async (dispatch) => {
     dispatch({type:"GET_ALL_VISITORS_REQUEST"})
      try {
            const {data} = await axios.get("/visitors");
            dispatch({type:"GET_ALL_VISITORS_SUCCESS",payload:data})
      } catch (error) {
            const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
            dispatch({type:"GET_ALL_VISITORS_FAIL",payload:message})
      }
     }


     //visitor clock out action
export const visitorClockOut = (id) => async (dispatch) => {
     dispatch({type:"VISITOR_CLOCKOUT_REQUEST"})
      try {
            const {data} = await axios.put(`/visitors/update/${id}`);
            dispatch({type:"VISITOR_CLOCKOUT_SUCCESS",payload:data})
      } catch (error) {
            const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
            dispatch({type:"VISITOR_CLOCKOUT_FAIL",payload:message})
      }
     }